import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
    </AnchorLinks>
    <h2>{`Usage`}</h2>
    <ul>
      <li parentName="ul">{`Used as the primary method to communicate actions & feedback to the user. These can be success messages, error messages, warning messages, or informative messages.`}</li>
      <li parentName="ul">{`Toasts can stack, with the most recent at the bottom.`}</li>
      <li parentName="ul">{`Should auto-dismiss after a short amount of time.`}</li>
      <li parentName="ul">{`The default button text is Dismiss but it can be adapted to other uses. Button text should be short, ideally one word, and no more than three.`}</li>
      <li parentName="ul">{`Toasts should live in the bottom right corner of the screen to avoid covering up page content whenever possible.`}</li>
    </ul>
    <h2>{`Variantions`}</h2>
    <h3>{`Primary`}</h3>
    <ul>
      <li parentName="ul">{`icon`}</li>
      <li parentName="ul">{`text in a single line`}</li>
      <li parentName="ul">{`action button`}
        <ul parentName="li">
          <li parentName="ul">{`default is dismiss`}</li>
        </ul>
      </li>
      <li parentName="ul">{`colors: primary, warning, danger, success`}</li>
      <li parentName="ul">{`max width 720px`}</li>
    </ul>
    <h3>{`Secondary`}</h3>
    <ul>
      <li parentName="ul">{`text in a single line`}</li>
      <li parentName="ul">{`action button`}
        <ul parentName="li">
          <li parentName="ul">{`default is dismiss`}</li>
        </ul>
      </li>
      <li parentName="ul">{`colors: primary, warning, danger, success`}</li>
      <li parentName="ul">{`max width 720px`}</li>
    </ul>
    <h3>{`Alternate`}</h3>
    <ul>
      <li parentName="ul">{`text in a single line`}</li>
      <li parentName="ul">{`colors: primary, warning, danger, success`}</li>
      <li parentName="ul">{`max width 720px`}</li>
    </ul>
    <h3>{`Large`}</h3>
    <ul>
      <li parentName="ul">{`can include icon`}</li>
      <li parentName="ul">{`can include action button`}
        <ul parentName="li">
          <li parentName="ul">{`default is dismiss`}</li>
        </ul>
      </li>
      <li parentName="ul">{`always includes text, which can wrap up to 3 lines max`}</li>
      <li parentName="ul">{`colors: primary`}</li>
      <li parentName="ul">{`max width 720px`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      